import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, EffectFade } from "swiper/modules";
import Joyride, { STATUS } from "react-joyride";
import VideoPlayer from "../VideoPlayer";
import VideoPlayerErrorBoundary from '../ErrorBoundary/VideoPlayerErrorBoundary';

// Import Swiper styles
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/effect-fade";
import { toast } from "react-toastify";

const VideoFeed = ({ videos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [runTour, setRunTour] = useState(() => {
    // Check localStorage on initial render
    return !localStorage.getItem('videoTourComplete');
  });
  const [isReady, setIsReady] = useState(false);
  const [muted, setMuted] = useState(true);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [isTourComplete, setIsTourComplete] = useState(false);
  const [currentVideos, setCurrentVideos] = useState([]);

  const tourSteps = [
    {
      target: ".video-container",
      content: "Tap anywhere on the video to play or pause",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".mute-button",
      content: "Click here to toggle sound on/off",
      placement: "left",
    },
    {
      target: ".swiper-slide",
      content: "Swipe up ⬆️ to see the next video",
      placement: "center",
    },
    {
      target: ".swiper-slide",
      content: "Swipe left ⬅️ to remove video",
      placement: "center",
    },
    {
      target: ".swiper-slide",
      content: "Swipe right ➡️ to see more details",
      placement: "center",
    },
  ];

  const tourStyles = {
    options: {
      arrowColor: "#000",
      backgroundColor: "#fff",
      overlayColor: "rgba(0, 0, 0, 0.85)",
      primaryColor: "#006351",
      textColor: "#000",
      fontWeight: 300,
      width: 300,
      zIndex: 200,
    },
    tooltipContainer: {
      textAlign: "center",
    },
    tooltipContent: {
      padding: "20px",
      fontSize: "14px",
      fontWeight: 300,
    },
    buttonNext: {
      backgroundColor: "#006351",
      fontSize: "13px",
      padding: "8px 15px",
      borderRadius: "15px",
    },
    buttonBack: {
      color: "#fff",
      marginRight: 10,
    },
    buttonClose: {
      display: "none",
    },
  };

  useEffect(() => {
    setCurrentVideos(videos);
  }, [videos]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true); // Set ready state after 1 second
    }, 1000);

    return () => clearTimeout(timer); // Clear timeout on unmount
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      // Save to localStorage to prevent showing tour again
      localStorage.setItem('videoTourComplete', 'true');
      setIsTourComplete(true);
    }
  };

  const handleMuted = (muted) => {
    setMuted(muted);
    setHasUserInteracted(true);
  };

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e, video) => {
    if (!touchStart) return;

    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStart - touchEnd;

    // If swipe distance is more than 100px and direction is left to right
    if (diff < -100 && video.actionUrl) {
      window.open(video.actionUrl, "_blank");
    }

    // If swipe distance is more than 100px and direction is left to right
    // remove the video from the feed
    if (diff > 100) {
      setCurrentVideos(
        currentVideos.filter((_, index) => index !== currentIndex)
      );
      toast.success("Video removed from feed");
    }

    setTouchStart(null);
  };

  console.log({ currentVideos, videos, currentIndex });

  return (
    <VideoPlayerErrorBoundary>
      <div className="h-screen w-full">
        <Joyride
          steps={tourSteps}
          run={runTour}
          continuous
          showProgress
          showSkipButton
          callback={handleJoyrideCallback}
          styles={tourStyles}
          locale={{
            back: "Back",
            close: "Close",
            last: "Let's start",
            next: "Next",
            skip: "Skip",
          }}
        />
        <Swiper
          direction="vertical"
          slidesPerView={1}
          spaceBetween={0}
          modules={[Virtual, EffectFade]}
          virtual
          className="h-full w-full swiper-vertical"
          onSlideChange={handleSlideChange}
          speed={300}
          resistance={false}
          resistanceRatio={0}
          threshold={20}
          touchRatio={1}
          touchAngle={45}
          grabCursor={true}
          watchSlidesProgress={true}
          preventInteractionOnTransition={true}
        >
          {currentVideos.map((video, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div
                className="h-screen w-full video-container"
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e, video)}
              >
                <VideoPlayer
                  videoIndex={index}
                  url={video.url}
                  title={video.title}
                  actionUrl={video.actionUrl}
                  description={video.description}
                  muted={muted}
                  hasUserInteracted={hasUserInteracted}
                  setMuted={handleMuted}
                  onEnded={() => {
                    if (index < currentVideos.length - 1) {
                      const swiper = document.querySelector(".swiper").swiper;
                      swiper.slideNext();
                    }
                  }}
                  onReady={() => {
                    // Ensure Joyride starts after video is ready
                    if (isReady && !isTourComplete) {
                      setRunTour(true);
                    }
                  }}
                  isVisible={currentIndex === index}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </VideoPlayerErrorBoundary>
  );
};

export default VideoFeed;
