import React, { useState, useEffect } from 'react';
import getMembershipStatus from '../api/membershipStatus';

function useMembershipStatus() {
    const [membershipStatus, setMembershipStatus] = useState(false);

    async function fetchData() {
        try {
            const membershipStatusData = await getMembershipStatus();
            setMembershipStatus(membershipStatusData.status);
        } catch (error) {
            console.error('Error fetching membership data:', error);
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);
    return membershipStatus;
}

export default useMembershipStatus;