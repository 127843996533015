import React, { useState, useEffect } from 'react';
import getProfile from '../api/getProfile';
import useAppContext from './useAppContext';

function useGetProfileInformation() {
    const {profileData, setProfileData} = useAppContext();
    async function fetchData() {
        try {
            const profileDataFromAPI = await getProfile();
            setProfileData(profileDataFromAPI);
        } catch (error) {
            console.error('Error fetching profile data data:', error);
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);
    return profileData;
}

export default useGetProfileInformation;