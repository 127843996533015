import { useState, useEffect } from 'react';
import getCompanyFAQ from '../api/getCompanyFAQ';

function useGetCompanyFAQs(companyId) {
    const [companyFAQ, setCompanyFAQ] = useState();

    async function fetchData(companyId) {
        try {
            const faqData = await getCompanyFAQ(companyId);
            setCompanyFAQ(faqData);
        } catch (error) {
            console.error('Error fetching company faq:', error);
        }
    }
    
    useEffect(() => {
        fetchData(companyId);
    }, [companyId]);

    return companyFAQ;
}

export default useGetCompanyFAQs;