import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import convertToIndianMoneyText from '../../utils/convertToIndianMoneyText';
import VideoModal from "../VideoModal/VideoModal";

const CompanyCard = ({ company, onNext, onPrev, onDetails }) => {
    const [showModal, setShowModal] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: () => onNext(),
        onSwipedRight: () => onPrev(),
        onSwipedUp: () => onNext(),
        onSwipedDown: () => onPrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleInvest = () => {
        handleCloseModal();
        onDetails(company.id);
    };

    if (!company) {
        return "";
    }

    return (
        <div className="relative flex flex-col bg-white p-4 rounded-2xl shadow-lg w-11/12 max-w-md mx-auto my-6 h-96" {...handlers}>
            <div className="relative mb-4" style={{ height: '33%' }}>
                <ReactPlayer
                    url={company.pitch_video}
                    playing={false}
                    controls={true}
                    width='100%'
                    height='100%'
                    className="react-player rounded-2xl"
                />
            </div>
            <h3 className="text-lg font-bold text-[#31C6BD]">{company.brand_name}</h3>
            <p className="text-sm font-bold mb-2">Location: {company.city}</p>
            <p className="text-xs mb-4">{company.elevator_pitch}</p>
            <div className="flex items-center mb-4 relative mt-4">
                <span className="text-xs absolute right-0 -top-10 translate-y-full">{company.days_left} days remaining</span>
                <div className="w-full bg-gray-300 rounded-full h-5 overflow-hidden">
                    <div className="bg-gradient-to-r from-[#31C6BD] to-[#20313B] h-full rounded-full" style={{ width: `${(company.fund_raised / company.raise_close_amount) * 100}%` }}></div>
                </div>
            </div>
            <div className="flex justify-between mt-4">
                <button onClick={handleOpenModal} className="w-1/2 bg-gray-400 text-white font-bold py-2 rounded-full shadow-lg mr-1">Watch Pitch</button>
                <button onClick={() => onDetails(company.id)} className="w-1/2 bg-[#31C6BD] text-white font-bold py-2 rounded-full shadow-lg ml-1">Invest</button>
            </div>
            <VideoModal
                show={showModal}
                onClose={handleCloseModal}
                videoUrl={company.pitch_video}
                onInvest={handleInvest}
            />
        </div>
    );
};

export default CompanyCard;