import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slides = [
  {
    title: "Welcome",
    description:
      "Discover and Invest in the next Big Startups all across the Country",
  },
  {
    title: "Weekly Pitches",
    description:
      "Attend Weekly Pitches with New Startups, raising Capital across varied Domains",
  },
  {
    title: "Compliance Free",
    description: "We take care of compliance.",
  },
  {
    title: "Exponential Returns",
    description:
      "Angels have made over 100x in Returns investing in Startups. What are you waiting for?",
  },
];

function LandingPage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const sliderRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const campaignCode = params.get("campaignCode");
    if (campaignCode) {
      localStorage.setItem("campaignCode", campaignCode);
    }
  }, [location.search]);

  const nextSlide = () => {
    if (currentSlide === slides.length - 1) {
      navigate("/login");
    } else {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    swipe: true,
    touchThreshold: 10,
  };

  return (
    <div
      className="h-screen flex flex-col bg-black"
      style={{
        background:
          "linear-gradient(137deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)",
      }}
    >
      {/* Top Section: Logo and Company Name */}
      <div className="h-[30vh] flex flex-col items-center justify-end">
        <img src="logo/primary_logo_vertical.png" alt="Logo" className="h-28 w-auto mb-10" />
      </div>

      {/* Middle Section: Slider */}
      <div className="h-[30vh] flex items-center px-4">
        <Slider ref={sliderRef} {...settings} className="w-full">
          {slides.map((slide, index) => (
            <div key={index} className="outline-none">
              <div className="flex flex-col items-center justify-center text-center">
                <h2 className="text-white text-2xl font-bold mb-2">
                  {slide.title}
                </h2>
                <p className="text-white text-sm mb-4 px-6">
                  {slide.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Bottom Section: Button and Pagination */}
      <div className="h-[25vh] flex flex-col justify-end pb-16">
        <div className="flex justify-center mb-8">
          <button
            onClick={nextSlide}
            className="backdrop-blur-sm text-white w-32 h-12 font-semibold transition-colors duration-200 border border-white/20 isolate aspect-video rounded-full bg-white/20 ring-1 ring-black/5"
          >
            {currentSlide === slides.length - 1 ? "Get Started" : "Next"}
          </button>
        </div>

        <div className="flex justify-center">
          <div className="flex space-x-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentSlide ? "bg-green-500" : "bg-white"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
