import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import loginWithPhoneNumber from "../api/login";
import useAppContext from "../hooks/useAppContext";
import Button from "../components/Form/button";
import InputText from "../components/Form/input";

function LoginPage() {
    const { loginData, setLoginData, setUserId } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [countryCode, setCountryCode] = useState('91'); // Default to India, without '+'
    const navigate = useNavigate();

    function handleOnChange(e) {
        setLoginData({ ...loginData, [e.target.id]: e.target.value });
    }

    function handleCountryChange(e) {
        setCountryCode(e.target.value);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setIsLoading(true);
            // Construct the phone number with ISD code and number, removing any '+' sign
            const formattedPhoneNumber = `${countryCode}${loginData.phone_number.replace(/^\+/, '')}`;
            const data = await loginWithPhoneNumber(formattedPhoneNumber);
            setUserId(data.user);
            navigate('/verify');
        } catch (error) {
            toast.error('😑 Please check the code and try again!', {
                className: 'toast-error',
            });
            console.error("Login failed: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="my-height flex flex-col justify-between items-center bg-white p-4 font-sans">
            {/* Top Section: Logo and Company Name */}
            <div className="flex flex-col items-center justify-center" style={{height: '50%'}}>
                <img src="black_logo.svg" alt="Logo" className="h-32 w-auto mb-2"/>
            </div>

            {/* Middle Section: Form Fields */}
            <div className="flex flex-col items-center justify-center w-full" style={{height: '25%'}}>
                <form onSubmit={handleSubmit} className="w-full max-w-sm">
                    {/* Country Dropdown */}
                    <div className="mb-4">
                        <select
                            id="country"
                            value={countryCode}
                            onChange={handleCountryChange}
                            className="w-full p-3 border border-gray-300 rounded bg-white text-black"
                        >
                            <option value="91">India (+91)</option>
                            <option value="1">USA (+1)</option>
                            <option value="44">UK (+44)</option>
                            {/* Add more countries as needed */}
                        </select>
                    </div>

                    {/* Phone Number Input */}
                    <div className="mb-4">
                        <InputText
                            id="phone_number"
                            placeholder="Enter your phone number"
                            type="tel"
                            onChange={handleOnChange}
                            className="w-full p-3 border border-gray-300 rounded bg-white text-black placeholder-gray-500"
                        />
                    </div>

                    {/* Button Section: Positioned below the input field */}
                    <div className="mb-8">
                        <Button
                            value={isLoading ? "Processing..." : "Send OTP"}
                            disabled={isLoading || !/^\d{10}$/.test(loginData.phone_number)}
                            className={`w-full h-12 text-white font-bold rounded shadow ${/^\d{10}$/.test(loginData.phone_number) ? 'bg-green-500' : 'bg-gray-300'}`}
                        />
                    </div>
                </form>
            </div>

            {/* Bottom Section: Pagination */}
            <div className="flex items-center justify-center mb-4" style={{height: '25%'}}>
                {/* Pagination dots or other elements can go here if needed */}
            </div>
        </div>
    );
}

export default LoginPage;