import React from "react";
import VideoFeed from "../VideoFeed";

const CompanyCard = ({ company, videos, onNext, onPrev, onDetails }) => {
  // Transform company data into video format

  return (
    <div className="relative h-screen w-full">
      {/* Video Player Section */}
      <div className="h-full w-full relative">
        <VideoFeed videos={videos} />
      </div>
    </div>
  );
};

export default CompanyCard;
