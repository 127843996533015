function Button(props) {
    return (
        <button
            className={`w-full h-12 text-white font-bold uppercase rounded-full transition-all duration-200 ${props.disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#006351] shadow-lg hover:shadow-xl active:shadow-inner cursor-pointer'}`}
            type="submit"
            id="login"
            disabled={props.disabled}
        >
            {props.value}
        </button>
    );
}

export default Button;