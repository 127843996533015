import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyCard from "../CompanyCard";

function CompanyContainer({ companies, membershipStatus }) {
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem("currentIndex");
    return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
  });
  const currentCompany = companies ? companies[currentIndex] : null;
  const navigate = useNavigate();

  const handleNext = () => {
    if (membershipStatus === true && companies) {
      const nextIndex = (currentIndex + 1) % companies.length;
      setCurrentIndex(nextIndex);
      localStorage.setItem("currentIndex", nextIndex.toString());
    } else {
      navigate("/membership/");
    }
  };

  const handlePrev = () => {
    if (membershipStatus === true && companies) {
      const prevIndex = (currentIndex - 1 + companies.length) % companies.length;
      setCurrentIndex(prevIndex);
      localStorage.setItem("currentIndex", prevIndex.toString());
    }
  };

  const handleDetails = (companyId) => {
    if (membershipStatus === true) {
      localStorage.setItem("currentIndex", currentIndex);
      navigate("/company/" + companyId);
    } else {
      navigate("/membership/");
    }
  };

  const videos = companies?.length > 0 ? companies.map((company) => ({
      url:
        company?.pitch_video ||
        "https://www.youtube.com/shorts/GRT5sY3So-g?feature=share", // company?.pitch_video
      title: company?.brand_name || "",
      description: company?.elevator_pitch || "",
      actionUrl: `/company/${company?.id}`,
    })) : [];
  return (
    <div className="h-screen w-full">
      <CompanyCard
        company={currentCompany}
        onNext={handleNext}
        onPrev={handlePrev}
        onDetails={handleDetails}
        videos={videos}
      />
    </div>
  );
}

export default CompanyContainer;
