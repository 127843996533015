import React, {useEffect, useState} from 'react';
import useFetchCompanyList from '../hooks/useFetchCompanyList';
import useMembershipStatus from '../hooks/useMembershipStatus';
import useAppContext from "../hooks/useAppContext";
import CompanyContainer from "../components/CompanyContainer";

function HomePage() {
    const companies = useFetchCompanyList();
    const membershipStatus = useMembershipStatus();
    const { user } = useAppContext();
    const [firstName, setFirstName] = useState('');


    useEffect(() => {
        const userProfile = localStorage.getItem('first_name');
        if (userProfile) {
            setFirstName(userProfile);
        }
        localStorage.setItem('currentIndex', 0);
    }, []);

    return (
        <div className="my-height flex flex-col"
             style={{background: 'linear-gradient(337deg, rgba(32,49,59,1) 10%, rgba(49,198,189,1) 100%)'}}> 
            <div className="flex-grow">
                <CompanyContainer companies={companies} membershipStatus={membershipStatus}/>
            </div>
        </div>
    );
}

export default HomePage;