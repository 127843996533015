function VerticalNavbar() {
    return (
        <nav className="my-height bg-gray-800 text-white">
            <ul className="list-none m-0 p-0">
                <li className="mb-2">
                    <a href="/home" className="block px-6 py-6 hover:bg-gray-700">
                        Home
                    </a>
                </li>
                <li className="mb-2">
                    <a href="/about" className="block px-6 py-6 hover:bg-gray-700">
                        About
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default VerticalNavbar;
