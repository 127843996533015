import axiosOpenInstance from "./axiosOpen";

async function verifyWithOTP(userId, otp){
    const response = await axiosOpenInstance.post('/user/verify/', {
        user_id: userId,
        otp: otp
    });
    // const {loginData, setLoginData, userId, setUserId} = useAppContext();
    return response.data;
}

export default verifyWithOTP;