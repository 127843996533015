import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faCalendarAlt,
    faCheckCircle,
    faFileAlt, faFileDownload,
    faQuestionCircle,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import useFetchCompanyData from '../hooks/useFetchCompanyData';
import ReactPlayer from "react-player";
import convertToIndianMoneyText from "../utils/convertToIndianMoneyText";

const CompanyPage = () => {
    const {companyId} = useParams();
    const navigate = useNavigate();
    const companyDetails = useFetchCompanyData(companyId);

    function returnToHome() {
        navigate('/home');
    }

    function proceedToInvest(name) {
        navigate('/disclaimer/' + companyId +'?name='+companyDetails.brand_name);
    }

    if (!companyDetails) {
        return null;
    }

    return (
        <div className="my-height text-white flex flex-col"
             style={{background: 'linear-gradient(337deg, rgba(32,49,59,1) 10%, rgba(49,198,189,1) 100%)'}}>
            {/* Top Section */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative sticky top-0 z-10">
                <button onClick={returnToHome}  className="absolute top-4 left-4 text-white z-20" >
                    <FontAwesomeIcon icon={faArrowLeft} size="2xl" />
                </button>
                <h1 className="text-2xl font-bold mb-2 mt-12 text-[#31C6BD] font-600">{companyDetails.brand_name}</h1>
                <p className="text-sm">{companyDetails.industry}</p>
                <div className="flex justify-between items-center">
                    <p className="text-sm">{`Location: ${companyDetails.city}`}</p>
                    <button onClick={proceedToInvest}
                            className="bg-[#31C6BD] text-white font-bold py-1 px-3 rounded-full">
                        Invest
                    </button>
                </div>
                <img src="logo.png" alt="Logo" className="absolute -bottom-2 left-2 opacity-20 h-48 w-auto"/>
            </div>

            {/* Middle Section */}
            <div className="flex-grow p-4 overflow-y-auto">
                <div className="relative mb-4" style={{height: '200px'}}>
                    <ReactPlayer
                        url={companyDetails.pitch_video}
                        playing={false}
                        controls={true}
                        width='100%'
                        height='100%'
                        className="react-player rounded-4xl"
                    />
                </div>
                <hr className="my-2 border-white opacity-30"/>
                <div className="grid grid-cols-2 gap-1">
                    {companyDetails.founders.slice(0, 4).map((founder, index) => (
                        <div key={index}
                             className="bg-gray-800 p-3 opacity-75 rounded-md flex items-center justify-between">
                            <div className="flex items-center">
                                <img src={founder.photo_url} alt={`${founder.first_name} ${founder.last_name}`}
                                     className="w-12 h-12 object-cover rounded-full mr-4"/>
                                <div>
                                    <h2 className="text-xs font-bold">{`${founder.first_name} ${founder.last_name}`}</h2>
                                    <p className="text-xs">Founder</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Team Size and Established Year Section */}
                <hr className="my-2 border-white opacity-30"/>
                <div className="grid grid-cols-2 gap-1">
                    <div className="bg-gray-800 p-3 opacity-75 rounded-md flex items-center justify-between">
                        <div className="flex items-center">
                            <div
                                className="w-12 h-12 object-cover rounded-full mr-4 flex items-center justify-center bg-gray-600">
                                <FontAwesomeIcon icon={faUsers} className="text-white"/>
                            </div>
                            <div>
                                <h2 className="text-xs font-bold">Team Size</h2>
                                <p className="text-xs">{companyDetails.team_size}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-800 p-3 opacity-75 rounded-md flex items-center justify-between">
                        <div className="flex items-center">
                            <div
                                className="w-12 h-12 object-cover rounded-full mr-4 flex items-center justify-center bg-gray-600">
                                <FontAwesomeIcon icon={faCalendarAlt} className="text-white"/>
                            </div>
                            <div>
                                <h2 className="text-xs font-bold">Established</h2>
                                <p className="text-xs">{companyDetails.establishment_year}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="w-full bg-white text-black p-4 rounded-t-2xl mt-2">
                    <div className="mt-0">
                        <div className="w-full bg-gray-300 rounded-full h-5">
                            <div className="bg-gradient-to-r from-[#31C6BD] to-[#20313B] h-full rounded-full"
                                 style={{width: `${(companyDetails.fund_raised / companyDetails.raise_close_amount) * 100}%`}}></div>
                        </div>
                        <div className="flex justify-between items-center mt-1">
                            <p className="text-xs text-left">{`${convertToIndianMoneyText(companyDetails.fund_raised)} Raised`}</p>
                            <p className="text-xs text-right">{`${companyDetails.days_left} days remaining`}</p>
                        </div>
                        <hr className="my-2 border-gray-500 opacity-30"/>
                        <div className="flex items-center justify-between mb-4 mt-4">
                            <div className="flex items-center">
                                <img src="verified_badge.svg" alt="Verified Badge" className="w-18 mr-2"/>
                                <div className="ml-2">
                                    <p className="text-sm font-bold text-[#31C6BD] font-bold">Verified</p>
                                    <p className="text-xs">All company data and documents provided are verified.</p>
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <a href={companyDetails.downloadable_deck} target="_blank" rel="noopener noreferrer"
                                   className="flex flex-col items-center text-center w-10">
                                    <img src="due_dilligence.svg" alt="DD Icon" className="w-10 h-10"/>
                                    <span className="text-sm text-[#31C6BD] font-bold">DD</span>
                                </a>
                                <a href={companyDetails.faq_link} target="_blank" rel="noopener noreferrer"
                                   className="flex flex-col items-center text-center w-10">
                                    <img src="faq.svg" alt="FAQ Icon" className="w-10 h-10"/>
                                    <span className="text-sm text-[#31C6BD] font-bold">FAQ</span>
                                </a>
                                <a href={companyDetails.downloadable_pitch} target="_blank" rel="noopener noreferrer"
                                   className="flex flex-col items-center text-center w-10">
                                    <img src="pitchdeck.svg" alt="Deck Icon" className="w-10 h-10"/>
                                    <span className="text-sm text-[#31C6BD] font-bold">Deck</span>
                                </a>
                            </div>
                        </div>

                        <hr className="my-2 border-gray-500 opacity-30"/>
                        <p className="text-md font-bold text-[#31C6BD] font-600">About Us:</p>
                        <p className="text-sm">{companyDetails.bio}</p>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default CompanyPage;
//
// <div className="flex items-center mb-4">
//     <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2"/>
//     <p className="text-sm font-bold">Verified</p>
//     <p className="text-xs ml-2">All documents provided by the startup are verified by us.</p>
// </div>
// <div className="flex justify-between mt-2">
//     <button className="flex items-center text-center">
//         <FontAwesomeIcon icon={faFileAlt} className="text-gray-400 mr-2"/>
//         <span className="text-sm">DD</span>
//     </button>
//     <button className="flex items-center text-center">
//         <FontAwesomeIcon icon={faQuestionCircle} className="text-gray-400 mr-2"/>
//         <span className="text-sm">FAQ</span>
//     </button>
//     <button className="flex items-center text-center">
//         <FontAwesomeIcon icon={faFileDownload} className="text-gray-400 mr-2"/>
//         <span className="text-sm">Deck</span>
//     </button>
// </div>