import React from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const VideoModal = ({ show, onClose, videoUrl, onInvest }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative bg-white w-full max-w-3xl mx-auto rounded-lg overflow-hidden">
                <div className="w-full" style={{ height: '80vh' }}>
                    <ReactPlayer
                        url={videoUrl}
                        playing={true}
                        controls={true}
                        width='100%'
                        height='100%'
                        className="react-player"
                    />
                </div>
                <div className="flex justify-between p-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-lg"
                    >
                        Back
                    </button>
                    <button
                        onClick={onInvest}
                        className="bg-[#31C6BD] text-white font-bold py-2 px-4 rounded-full shadow-lg"
                    >
                        Invest
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoModal;