import { useContext } from "react";
import { AppContext } from "../context/store";

function useAppContext(){
    const {appState, setAppState} = useContext(AppContext);

    
    function setLoginData(data){
        setAppState({
            ...appState,
            loginData:{
                ...appState.loginData,
                ...data
              }
        })
    }

    function setUserId(data){
        setAppState({
            ...appState,
            userId:data
        })
    }

    function setProfileData(data){
        setAppState(
            {
                ...appState,
                profileData:{
                    ...appState.profileData,
                    ...data
                }
            }
        )
    }

    function setCompanyListData(data){
        setAppState(
            {
                ...appState,
                companyList: data
            }
        )
    }
    
    function setCompanyDetailData(data){
        setAppState(
            {
                ...appState,
                companyDetail: data
            }
        )
    }
    
    return {
        userId:appState.userId,
        loginData:appState.loginData,
        profileData:appState.profileData,
        companyList:appState.companyList,
        companyDetail:appState.companyDetail,
        setUserId,
        setLoginData,
        setProfileData,
        setCompanyListData,
        setCompanyDetailData
    };
}

export default useAppContext;