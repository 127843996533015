import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyCard from "../CompanyCard";
import {useSwipeable} from "react-swipeable";

function CompanyContainer({ companies, membershipStatus }) {
    const [currentIndex, setCurrentIndex] = useState(() => {
        const savedIndex = localStorage.getItem('currentIndex');
        return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
    });
    const currentCompany = companies ? companies[currentIndex] : null;
    const navigate = useNavigate();

    const handleNext = () => {
        if (membershipStatus === true) {
            const nextIndex = (currentIndex + 1) % companies.length;
            setCurrentIndex(nextIndex);
        } else {
            // Changed on 21st July, as per discussion with Vishwesh.
            // Need to show membership Directly.
            // const nextIndex = (currentIndex + 1);
            // setCurrentIndex(nextIndex);
            // if (currentIndex + 1 >= companies.length) {
            //     navigate('/membership/');
            // }
            navigate('/membership/');
        }
    };

    const handlePrev = () => {
        if (membershipStatus === true) {
            const nextIndex = (currentIndex - 1) % companies.length;
            setCurrentIndex(nextIndex);
        } else {
            const nextIndex = (currentIndex - 1);
            setCurrentIndex(nextIndex);
            if (currentIndex - 1 >= companies.length) {
                navigate('/membership/');
            }
        }
    }

    const handleDetails = () => {
        if (membershipStatus === true) {
            localStorage.setItem('currentIndex', currentIndex);
            navigate('/company/' + currentCompany.id);
        } else {
            navigate('/membership/');
        }
    };

    return (
        <CompanyCard
            company={currentCompany}
            onNext={handleNext}
            onPrev={handlePrev}
            onDetails={handleDetails}
        />
    );
}

export default CompanyContainer;