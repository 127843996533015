import VerticalNavbar from "../components/NavBar";

function AboutPage(){
    return (
        <div >
            About Us Amigo !
        </div>
    );
}

export default AboutPage;