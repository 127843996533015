import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import convertToIndianMoneyText from "../utils/convertToIndianMoneyText";
import createInvestment from "../api/createInvestment";
import primaryLogo from '../assets/logo/primary_logo.png';
const InvestmentPage = () => {
    const { companyId } = useParams(); // Get URL params
    const location = useLocation(); // Get the location object to access query parameters
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search); // Create a URLSearchParams object
    const nameParam = searchParams.get('name'); // Get the 'name' query parameter
    const [amount, setAmount] = useState(50000); // Start with the minimum amount
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState();
    const [investmentId, setInvestmentId] = useState();

    const handleBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    const handleIncrement = () => {
        setAmount(prevAmount => prevAmount + 50000); // Increase amount by 50,000
    };

    const handleDecrement = () => {
        if (amount > 50000) {
            setAmount(prevAmount => prevAmount - 50000); // Decrease amount by 50,000
        }
    };

    function formatNumberToIndian(num) {
        // Convert the number to a string
        let numStr = num.toString();

        // Split the number into integer and decimal parts if any
        let [integerPart, decimalPart] = numStr.split('.');

        // Use a regular expression to insert commas according to the Indian numbering system
        let lastThreeDigits = integerPart.slice(-3);
        let otherDigits = integerPart.slice(0, -3);

        // Format the integer part with commas
        let formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;

        // Concatenate the integer part and the decimal part (if any)
        return decimalPart ? formattedIntegerPart + "." + decimalPart : formattedIntegerPart;
    }

    async function handleInvest(){
        setLoading(true);
        const response = await createInvestment(companyId, amount);
        const orderId = response.order_id;
        const investmentId = response.investment_id;
        setOrderId(orderId);
        setInvestmentId(investmentId);
    }

    useEffect(() => {
        if(orderId && investmentId){
            window.bolt.launch(orderId, {
                responseHandler: (BOLT) => {
                    navigate('/investment/verify/'+investmentId);
                    setLoading(false);
                },
                catchException: (BOLT) => {
                    alert(BOLT.message);
                    setLoading(false);
                }
            });
            setLoading(false);
        }
    }, [orderId, investmentId]);

    return (
        <div className="my-height text-white flex flex-col"
             style={{ background: 'linear-gradient(137deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)' }}>
            {/* Header Section */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative sticky top-0 z-10">
                <button className="absolute top-4 left-4 text-white z-50" onClick={handleBack}>
                    <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                </button>
                <h1 className="text-2xl font-bold mb-2 mt-12 text-[#006351] font-600">Invest in {nameParam}</h1>
                <p className="text-xs">Enter amount to invest. Minimum ticket size is Rs.50,000/-</p>
                <img src={primaryLogo} alt="Logo" className="absolute -bottom-7 -left-10 h-40 w-auto z-[-1] opacity-40"/>
            </div>

            {/* Middle Section */}
            <div className="flex flex-col items-center justify-center flex-grow">
                <div className="flex items-center space-x-4">
                    <button className="text-3xl text-[#006351]" onClick={handleDecrement}>-</button>
                    <div className="text-3xl font-bold bg-white text-black rounded px-4 py-2">{formatNumberToIndian(amount)}</div>
                    <button className="text-3xl text-[#006351]" onClick={handleIncrement}>+</button>
                </div>
                <p className="text-lg mt-4">{`Rupees `+convertToIndianMoneyText(amount)}</p>
            </div>

            {/* Bottom Section */}
            <div className="flex justify-center mb-8 w-full">
                <button className="bg-[#006351] text-white font-bold py-2 px-8 rounded-full shadow-lg hover:shadow-xl active:shadow-inner w-64"
                        onClick={handleInvest}>
                    {loading?'Please Wait':'Invest'}
                </button>
            </div>
        </div>
    );
};

export default InvestmentPage;