import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetCompanyFAQs from '../hooks/useGetCompanyFAQs';

const AccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    //const [FAQs, setFAQs] = useState(false);
    
    
    return (
        <div className="mb-2 shadow-md">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full text-left px-4 py-2 bg-gray-800 text-white font-bold"
            >
                {question}
            </button>
            {isOpen && (
                <div className="p-4 bg-gray-100">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
};

// const FAQs = [
//     { question: "What is InvestorHub?", answer: "InvestorHub is a platform where users can discover startups and invest as angels." },
//     { question: "How do I start investing?", answer: "You can start investing by creating an account, browsing through startup listings, and choosing a project to invest in." },
//     { question: "Is there a minimum investment amount?", answer: "Yes, the minimum investment amount varies depending on the startup's requirements." },
//     // Add more FAQs as needed
// ];

const FAQPage = () => {
    const { companyId } = useParams();
    const [FAQs, setFAQs] = useState([]);
    const data = useGetCompanyFAQs(companyId);
    if(!data){
        return ""
    }
    return (
        <div className="max-w-4xl mx-auto p-8">
            <h1 className="text-3xl font-bold text-center mb-4">Frequently Asked Questions</h1>
            {data.map((faq, index) => (
                <AccordionItem key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>
    );
};

export default FAQPage;
