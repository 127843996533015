import axios from 'axios';
import Cookies from 'js-cookie';

const axiosClosedInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// Adding a request interceptor
axiosClosedInstance.interceptors.request.use(
    config => {
      // Retrieve the access token from cookies
      const accessToken = Cookies.get('accessToken');
      // If the token exists, add it to the headers
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    }
);

export default axiosClosedInstance;
