import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const CustomSelect = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const countries = [
        { code: '91', name: 'India', flag: '🇮🇳' },
        { code: '1', name: 'USA', flag: '🇺🇸' },
        { code: '44', name: 'UK', flag: '🇬🇧' },
    ];

    const selectedCountry = countries.find(c => c.code === value);

    return (
        <div className="relative">
            {/* Selected Option Button */}
            <button
                type="button"
                className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm 
                         flex items-center justify-between text-left
                         hover:border-green-500 focus:outline-none focus:ring-2 
                         focus:ring-green-500 focus:border-transparent
                         transition-all duration-200"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center space-x-2">
                    <span className="text-xl">{selectedCountry?.flag}</span>
                    <span className="text-gray-900">
                        {selectedCountry?.name} (+{selectedCountry?.code})
                    </span>
                </div>
                <FontAwesomeIcon 
                    icon={faChevronDown}
                    className={`w-4 h-4 text-gray-400 transition-transform duration-200
                             ${isOpen ? 'transform rotate-180' : ''}`}
                />
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 
                            rounded-lg shadow-lg overflow-hidden
                            transform transition-all duration-200 ease-out">
                    <div className="max-h-60 overflow-auto">
                        {countries.map((country) => (
                            <div
                                key={country.code}
                                className={`p-3 flex items-center space-x-2 cursor-pointer
                                        hover:bg-gray-50 transition-colors duration-150
                                        ${country.code === value ? 'bg-green-50 text-green-600' : 'text-gray-900'}`}
                                onClick={() => {
                                    onChange(country.code);
                                    setIsOpen(false);
                                }}
                            >
                                <span className="text-xl">{country.flag}</span>
                                <span>{country.name} (+{country.code})</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelect;