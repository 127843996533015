import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBalanceScale,
    faCheckCircle,
    faExclamationTriangle,
    faGem,
    faMinus,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

function MembershipThanksPage() {
    const navigate = useNavigate();

    function goHome(){
        navigate("/home");
    }

    return (
        <div className="my-height text-white flex flex-col"
             style={{ background: 'linear-gradient(137deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)' }}>
            {/* Top Section */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative">
                <h1 className="text-2xl font-bold mb-2 mt-12">Membership Confirmation</h1>
                <p className="text-sm">Thank you for your membership</p>
                <img src="logo_green.svg" alt="Logo" className="absolute -bottom-2 left-2 h-48 w-auto" />
            </div>

            {/* Middle Section */}
            <div className="flex-grow flex flex-col items-center justify-center text-white p-4">
                <div className="container mx-auto max-w-4xl text-center">
                    <div className="mb-6">
                        <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-green-500 mb-4" />
                        <h1 className="text-4xl font-bold">Confirmed!</h1>
                    </div>
                    <p className="text-xl mb-8">
                        You now have complete access to BloomSight Network
                    </p>

                    <div className="mt-6">
                        <button onClick={goHome} className="bg-[#31C6BD] hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full text-lg transition-colors shadow-lg">
                            Explore Startups
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MembershipThanksPage;
