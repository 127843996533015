import updateProfileWithInformation from "../../api/profile";
import useAppContext from "../../hooks/useAppContext";
import Button from "../Form/button";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import InputText from "../Form/input";

function ProfileForm() {
    const { profileData, setProfileData } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
    }, [profileData]);

    async function handleOnChange(e) {
        e.preventDefault();
        const { id, value } = e.target;
        const updatedProfileData = { ...profileData, [id]: value };
        setProfileData(updatedProfileData);
        localStorage.setItem('first_name', profileData.first_name);
        // Check if all required fields are filled
        const isFormValid = updatedProfileData.first_name && updatedProfileData.last_name && updatedProfileData.email;
        setIsFormValid(isFormValid);
    }

    async function handleOnSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            let campaignCode = localStorage.getItem('campaignCode');
            let country = "India";
            if (campaignCode && campaignCode.length > 0) {
                country = campaignCode;
            }
            await updateProfileWithInformation(
                profileData.first_name,
                profileData.last_name,
                profileData.email,
                "https://linkedin.com/",
                "https://twitter.com/",
                "https://instagram.com/",
                "Random Bio",
                country,
                "Country",
                "https://image.com/png.png"
            );
            //toast('✅ Welcome To The Team', {});
            navigate('/membership');
        } catch (e) {
            //toast('😑 Something Went Wrong.', {});
            console.error(e);
        } finally {
            setIsLoading(false);
            console.log("API call completed");
        }
    }

    return (
        <div className="my-height w-full flex flex-col items-center"
             style={{ background: 'linear-gradient(320deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)' }}
        >
            {/* Top Section: Header */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl mb-8 h-52 relative">
                <h1 className="text-xl font-bold mb-2 mt-12">Membership Registration</h1>
                <p className="text-sm">Add your profile details, to register on Bloomsight</p>
                <img src="logo_green.svg" alt="Logo" className="absolute -bottom-2 left-2 h-48 w-auto"/>
            </div>

            {/* Middle Section: Form Fields */}
            <form onSubmit={handleOnSubmit} className="w-full max-w-xs flex flex-col items-center z-10">
                <InputText
                    id="first_name"
                    placeholder="First Name"
                    type="text"
                    onChange={handleOnChange}
                    className="w-full mb-4 bg-white text-black border-gray-300 p-2 rounded-sm"
                />
                <InputText
                    id="last_name"
                    placeholder="Last Name"
                    type="text"
                    onChange={handleOnChange}
                    className="w-full mb-4 bg-white text-black border-gray-300 p-2 rounded-sm"
                />
                <InputText
                    id="email"
                    placeholder="Email"
                    type="email"
                    onChange={handleOnChange}
                    className="w-full mb-4 bg-white text-black border-gray-300 p-2 rounded-sm"
                />
                <Button
                    value={isLoading ? "Processing..." : "Save"}
                    disabled={isLoading || !isFormValid}
                    type="submit"
                    className={`w-full h-12 text-white font-bold uppercase rounded-full transition-all duration-200 ${isFormValid ? 'bg-[#31C6BD] shadow-lg hover:shadow-xl active:shadow-inner' : 'bg-gray-300 cursor-not-allowed'}`}
                />
            </form>

            {/* Bottom Section: Background Logo */}
            <div className="absolute -bottom-4 -right-2 ">
                <img src="logo_green.svg" alt="Background Logo" className="h-96 w-auto"/>
            </div>
        </div>
    );
}

export default ProfileForm;