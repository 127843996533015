import React from "react";
const appStore =  {
    loginData:{
      phone_number:null,
      otp:['','','','','',''],
      isMember:false,
    },
    userId:null,
    profileData:{
      first_name:null,
      last_name:null,
      email:null,
      linkedin:null,
      twitter:null,
      instagram:null,
      bio:null,
      city:null,
      country:null,
      photo_url:null,
    },
    companyList:[],
    companyDetail:{}
  }

export default appStore;
export const AppContext = React.createContext(null);