function InputText(props) {
   return (
       <input
           className="w-full h-12 bg-white border border-gray-300 rounded px-4 text-black placeholder-gray-500 mb-4"
           id={props.id}
           type={props.type}
           placeholder={props.placeholder}
           onChange={props.onChange}
       />
   );
}

export default InputText;