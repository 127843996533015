function convertToIndianMoneyText(value) {
    const units = ["", "Thousand", "Lakh", "Crore"];
    let result = "";
    let num = value;

    if (num === 0) {
        return "Zero";
    }

    // Helper function to convert a number to words
    function numberToWords(num) {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const teens = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        let word = "";

        if (num > 99) {
            word += ones[Math.floor(num / 100)] + " Hundred ";
            num %= 100;
        }

        if (num > 10 && num < 20) {
            word += teens[num - 11] + " ";
        } else {
            word += tens[Math.floor(num / 10)] + " ";
            word += ones[num % 10] + " ";
        }

        return word.trim();
    }

    // Split the number into groups of digits according to the Indian numbering system
    function splitNumber(num) {
        let parts = [];
        if (num > 99) {
            parts.push(num % 1000);
            num = Math.floor(num / 1000);
        }
        while (num > 0) {
            parts.push(num % 100);
            num = Math.floor(num / 100);
        }
        return parts.reverse();
    }

    let parts = splitNumber(num);
    let len = parts.length;

    for (let i = 0; i < len; i++) {
        if (parts[i] > 0) {
            result += numberToWords(parts[i]) + " " + units[len - 1 - i] + " ";
        }
    }

    return result.trim();
}
export default convertToIndianMoneyText;