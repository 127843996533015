import React, {useEffect, useState} from 'react';
import useFetchCompanyList from '../hooks/useFetchCompanyList';
import useMembershipStatus from '../hooks/useMembershipStatus';
import useAppContext from "../hooks/useAppContext";
import CompanyContainer from "../components/CompanyContainer";

function HomePage() {
    const companies = useFetchCompanyList();
    const membershipStatus = useMembershipStatus();
    const { user } = useAppContext();
    const [firstName, setFirstName] = useState('');


    useEffect(() => {
        const userProfile = localStorage.getItem('first_name');
        if (userProfile) {
            setFirstName(userProfile);
        }
        localStorage.setItem('currentIndex', 0);
    }, []);

    return (
        <div className="my-height flex flex-col"
             style={{background: 'linear-gradient(337deg, rgba(32,49,59,1) 10%, rgba(49,198,189,1) 100%)'}}>
            <div className="w-full bg-black text-white p-6 rounded-b-2xl mb-8 h-52 relative">
                <h1 className="text-xl font-bold mb-2 mt-12">Hello {firstName},</h1>
                <p className="text-sm">Let's Start Exploring</p>
                <img src="logo.png" alt="Logo" className="absolute -bottom-2 left-2 opacity-10 h-48 w-auto"/>
            </div>

            <div className="flex-grow">
                <CompanyContainer companies={companies} membershipStatus={membershipStatus}/>
            </div>
        </div>
    );
}

export default HomePage;