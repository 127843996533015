import axiosClosedInstance from "./axiosClosed";

async function updateProfileWithInformation(first_name, last_name, email, linkedin, twitter, instagram, bio, city, country, photo_url){
    const response = await axiosClosedInstance.patch('/user/profile/', {
        first_name:first_name,
        last_name:last_name,
        email:email,
        linkedin:linkedin,
        twitter:twitter,
        instagram:instagram,
        bio:bio,
        city:city,
        country:country,
        photo_url:photo_url,
    });
    return response.data;
}

export default updateProfileWithInformation;