import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';

const slides = [
    {
        title: "Welcome",
        description: "Discover and Invest in the next Big Startups all across the Country",
    },
    {
        title: "Weekly Pitches",
        description: "Attend Weekly Pitches with New Startups, raising Capital across varied Domains",
    },
    {
        title: "Compliance Free",
        description: "We take care of compliance.",
    },
    {
        title: "Exponential Returns",
        description: "Angels have made over 100x in Returns investing in Startups. What are you waiting for?",
    }
];

function LandingPage() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const campaignCode = params.get('campaignCode');
        if (campaignCode) {
            localStorage.setItem('campaignCode', campaignCode);
        }
    }, [location.search]);

    const nextSlide = () => {
        if (currentSlide === slides.length - 1) {
            navigate('/login');  // Replace with the actual path for the next page
        } else {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }
    };

    const previousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    const handlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: previousSlide,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div className="my-height flex flex-col bg-black p-4"
             style={{
                 background: 'linear-gradient(137deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)'
             }}
             {...handlers}>
            {/* Top Section: Logo and Company Name */}
            <div className="flex flex-col items-center justify-center" style={{ height: '50%' }}>
                <img src="logo.png" alt="Logo" className="h-20 w-auto mb-2"/>
                <h1 className="text-white text-md font-semibold">BloomSight</h1>
            </div>

            {/* Middle Section: Title and Description */}
            <div className="flex flex-col items-center justify-center text-center" style={{ height: '25%' }}>
                <h2 className="text-white text-2xl font-bold mb-2">{slides[currentSlide].title}</h2>
                <p className="text-white text-sm mb-4">{slides[currentSlide].description}</p>
            </div>

            {/* Spacer */}
            <div className="flex-grow"></div>

            {/* Bottom Section: Pagination */}
            <div className="flex items-center justify-center mb-4">
                <div className="flex space-x-1 mb-4 justify-center">
                    {slides.map((_, index) => (
                        <div
                            key={index}
                            className={`w-2 h-2 rounded-full ${index === currentSlide ? 'bg-green-500' : 'bg-white'}`}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LandingPage;