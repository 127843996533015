import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import useGetProfileInformation from '../hooks/useGetProfileInformation';
import createMembershipSubscription from '../api/createMembershipSubscription';
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import primaryLogo from '../assets/logo/primary_logo.png';

function MembershipPage() {
    const [loading, setLoading] = useState(false);
    const profileData = useGetProfileInformation();
    const navigate = useNavigate();

    const handlePayUSubscription = (e) => {
        setLoading(true);
        e.preventDefault();
        let response = profileData.pg_subscription_id;

        // Replace single quotes with double quotes
        response = response.replace(/'/g, '"');

        // Parse the JSON string to an object
        let jsonObject = JSON.parse(response);
        jsonObject.amount = parseInt(jsonObject.amount);
        window.bolt.launch(jsonObject, {
            responseHandler: (BOLT) => {
                console.log(BOLT.response.txnStatus);
                navigate('/membership/verify/');
                setLoading(false);
            },
            catchException: (BOLT) => {
                alert(BOLT.message);
                setLoading(false);
            }
        });
        setLoading(false);
    }

    return (
        <div className="my-height flex flex-col overflow-y-auto"
             style={{background: 'linear-gradient(337deg, rgba(32,49,59,1) 10%, rgba(49,198,189,1) 100%)'}}>
            {/* Header Section */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative sticky top-0 z-10">
                <button onClick={() => navigate(-1)} className="absolute top-4 left-4 text-white z-50">
                    <FontAwesomeIcon icon={faArrowLeft} size="2xl"/>
                </button>
                <h1 className="text-2xl font-bold mb-2 mt-12 font-600">Bloomsight Membership</h1>
                <p className="text-sm">You will be able to enjoy crazy benefits by becoming a member</p>
                <img src={primaryLogo} alt="Logo" className="absolute -bottom-7 -left-10 h-40 w-auto z-[-1] opacity-40"/>
            </div>

            {/* Content Section */}
            <div className="flex-grow p-6 space-y-4">
                <div className="flex items-start space-x-4">
                    <img src="magic.svg" alt="Balance Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Weekly Start-Up pitches</h2>
                        <p className="text-sm">Gain exclusive access to new and exciting startup pitches every week.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <img src="card.svg" alt="Risk Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Unlimited Startup browsing</h2>
                        <p className="text-sm">Explore an unlimited number of startups at your leisure and find those
                            that align with your investment goals.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <img src="growth.svg" alt="Benefit Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Ability to Grow Capital Exponentially</h2>
                        <text className="text-sm">Leverage your investment insights and our resources to potentially
                            grow your capital up to ten times.
                        </text>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="p-6">
                <button
                    className="w-full bg-[#006351] text-white font-bold py-3 rounded-full shadow-lg hover:shadow-xl active:shadow-inner "
                    onClick={handlePayUSubscription}
                >
                    {loading?'Please Wait':'Become A Member'}
                </button>
            </div>
        </div>
    );
}

export default MembershipPage;