import OTPInput from "../components/Otp";
import useAppContext from "../hooks/useAppContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import verifyWithOTP from "../api/verify";
import { toast } from "react-toastify";
import Button from "../components/Form/button";

function OtpPage() {
    const { userId, loginData, setLoginData } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (element, index) => {
        const newOtp = [...loginData.otp];
        newOtp[index] = element.value;
        setLoginData({ otp: newOtp });

        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    async function handleSubmit(e) {
        try {
            e.preventDefault();
            setIsLoading(true);
            Cookies.remove('accessToken', { path: '' });
            Cookies.remove('refreshToken', { path: '' });
            const otp = loginData.otp.join('');
            console.log("Submitting OTP:", otp);
            try {
                const resp = await verifyWithOTP(userId, otp);
                console.log("API Response:", resp);
                const userRegistered = resp.user_registered;
                const accessToken = resp.tokens.access;
                const refreshToken = resp.tokens.refresh;
                Cookies.set('accessToken', accessToken, { expires: 30 });
                Cookies.set('refreshToken', refreshToken, { expires: 365 });
                if (userRegistered) {
                    //toast('🏠 Glad To Have You Back!', {});
                    navigate('/home');
                } else {
                    //toast('🎬 Lets Get You Started!', {});
                    navigate('/profile');
                }
            } catch (e) {
                console.log(e);
                toast('😑 Please Check The Code & Try Again!', {});
            }
        } catch (error) {
            console.error("Login failed: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="my-height flex flex-col justify-between items-center bg-white text-black p-4">
            {/* Top Section: Logo and Company Name */}
            <div className="flex flex-col items-center justify-center" style={{ height: '50%' }}>
                <img src="black_logo.svg" alt="Logo" className="h-32 w-auto mb-2" />
            </div>

            {/* Middle Section: Form Fields */}
            <div className="w-full flex flex-col items-center justify-center flex-grow">
                <div className="flex justify-center mb-8">
                    {loginData.otp.map((data, index) => (
                        <input
                            className="w-12 h-14 text-center text-xl bg-white border border-gray-300 rounded-sm text-black placeholder-gray-400 m-1"
                            type="tel"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                        />
                    ))}
                </div>
                <button
                    type="submit"
                    className={`w-full h-12 text-white font-bold uppercase rounded-full transition-all duration-200 ${loginData.otp.join('').length < 6 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#31C6BD] shadow-lg hover:shadow-xl active:shadow-inner cursor-pointer'}`}
                    disabled={isLoading || loginData.otp.join('').length < 6}
                    onClick={handleSubmit}
                >
                    {isLoading ? "Verifying..." : "Verify"}
                </button>
            </div>
        </div>
    );
}

export default OtpPage;