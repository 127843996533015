import React from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DisclaimerPage = () => {
    const { companyId } = useParams(); // Get URL params
    const location = useLocation(); // Get the location object to access query parameters
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search); // Create a URLSearchParams object
    const nameParam = searchParams.get('name'); // Get the 'name' query parameter

    const handleNext = () => {
        navigate(`/invest/${companyId}?name=${nameParam}`); // Update the path with UUID and name
    };

    return (
        <div className="my-height flex flex-col overflow-y-auto"
             style={{background: 'linear-gradient(337deg, rgba(32,49,59,1) 10%, rgba(49,198,189,1) 100%)'}}>
            {/* Header Section */}
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative sticky top-0 z-10">
                <button onClick={() => navigate(-1)} className="absolute top-4 left-4 text-white z-50">
                    <FontAwesomeIcon icon={faArrowLeft} size="2xl"/>
                </button>
                <h1 className="text-2xl font-bold mb-2 mt-12 text-[#31C6BD] font-600">Invest In, {nameParam}</h1>
                <p className="text-sm">Before You Invest</p>

                <img src="logo_green.svg" alt="Logo" className="absolute -bottom-2 left-2 h-48 w-auto"/>
            </div>

            {/* Content Section */}
            <div className="flex-grow p-4 space-y-4">
                <div className="flex items-start space-x-4">
                    <img src="alert.svg" alt="Risk Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Risks of Angel Investing</h2>
                        <p className="text-sm">Angel investing involves significant risk and is suitable only for
                            investors who can bear the loss of their entire investment.</p>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <img src="diamond.svg" alt="Benefit Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Potential Benefits</h2>
                        <text className="text-sm">Despite the risks, angel investing offers the potential for financial
                            returns and the
                            opportunity to support innovative startups.
                        </text>
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <img src="balance.svg" alt="Balance Icon" className="w-10 h-10"/>
                    <div className="text-white">
                        <h2 className="text-lg mb-0 font-bold">Balance Your Portfolio</h2>
                        <p className="text-sm">Consider balancing your investment portfolio by limiting the amount
                            invested in these
                            high-risk opportunities.</p>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="p-6">
                <button
                    className="w-full bg-[#31C6BD] text-white font-bold py-3 rounded-full shadow-lg hover:shadow-xl active:shadow-inner "
                    onClick={handleNext}
                >
                    Proceed To Invest
                </button>
            </div>
        </div>
    );
};

export default DisclaimerPage;