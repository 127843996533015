import React, {useState} from 'react';
import useAppContext from "../../hooks/useAppContext";
import Button from '../Form/button';
import verifyWithOTP from '../../api/verify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";

function OTPInput() {
    

}

export default OTPInput;
