import React, { useState, useEffect } from 'react';
import getCompanyList from '../api/companyList';

function useFetchCompanyList() {
    const [companies, setCompanies] = useState();

    async function fetchData() {
        try {
            const companyList = await getCompanyList();
            setCompanies(companyList);
        } catch (error) {
            console.error('Error fetching company list:', error);
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);
    return companies;
}

export default useFetchCompanyList;