import { useState, useEffect } from 'react';
import getCompanyDetail from "../api/companyDetail"

function useFetchCompanyData(uuid) {
    const [companyData, setCompanyData] = useState();

    async function fetchData(uuid) {
        try {
            const companyData = await getCompanyDetail(uuid);
            setCompanyData(companyData);
        } catch (error) {
            console.error('Error fetching company list:', error);
        }
    }
    
    useEffect(() => {
        fetchData(uuid);
    }, [uuid]);

    return companyData;
}

export default useFetchCompanyData;