import React, { useState, useEffect } from 'react';
import getMembershipStatus from '../api/membershipStatus';
import { useNavigate, useParams } from 'react-router-dom';
import getInvestmentStatus from '../api/getInvestmentStatus';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function VerifyInvestment() {
    const { orderId } = useParams();
    const [status, setStatus] = useState('Processing...');
    const [count, setCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const checkInvestment = async () => {
            const response = await getInvestmentStatus(orderId);
            if (response.investment_status === 'verified') {
                //toast('💰 Thank you for your investment!', {});
                setStatus('Success');
                navigate('/investment/thankyou');
                return true;
            }
            return false;
        };

        if (count < 100) {
            const interval = setInterval(async () => {
                const success = await checkInvestment();
                if (success || count >= 99) {
                    clearInterval(interval);
                    if (!success) {
                        setStatus('Payment Failed');
                    }
                }
                setCount(prevCount => prevCount + 1);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [count]);

    return (
        <div className="my-height flex flex-col"
             style={{ background: 'linear-gradient(137deg, rgba(32,49,59,1) 30%, rgba(49,198,189,1) 100%)' }}>
            <div className="w-full bg-black text-white p-6 rounded-b-2xl h-52 relative">
                <h1 className="text-2xl font-bold mb-2 mt-12">Investment Verification</h1>
                <p className="text-sm">Please wait while we verify your payment status</p>
                <img src="logo_green.svg" alt="Logo" className="absolute -bottom-2 left-2 h-48 w-auto" />
            </div>

            <div className="flex-grow flex flex-col items-center justify-center text-white p-4">
                <h1 className="text-3xl font-bold mb-4">Verifying Payment</h1>
                {status === 'Processing...' && (
                    <div className="w-16 h-16 border-4 border-t-4 border-t-transparent border-gray-400 rounded-full animate-spin mb-4"></div>
                )}
                {status === 'Success' && (
                    <div className="flex items-center text-green-500">
                        <FontAwesomeIcon icon={faCheckCircle} size="2x" className="mr-2" />
                        <p className="text-xl">Payment Verified Successfully</p>
                    </div>
                )}
                {status === 'Payment Failed' && (
                    <div className="flex items-center text-red-500">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="mr-2" />
                        <p className="text-xl">Payment Verification Failed</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default VerifyInvestment;