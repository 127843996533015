import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import AboutPage from "./pages/about";
import CompanyPage from "./pages/company";
import OtpPage from "./pages/otp";
import ProfilePage from "./pages/profile";
import LandingPage from "./pages/landingpage";
import FAQPage from "./pages/faq";
import MembershipPage from "./pages/membership";
import VerifyMembership from "./pages/verifymembership";
import DisclaimerPage from "./pages/disclaimer";
import VerifyInvestment from "./pages/verifyInvestment";
import MembershipThanksPage from "./pages/membershipThanks";
import InvestmentThanksPage from "./pages/investmentThanks";
import Investment from "./pages/investment";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/verify",
      element: <OtpPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path: "/about",
      element: <AboutPage />,
    },
    {
      path: "company/:companyId",
      element: <CompanyPage />,
    },
    {
      path: "disclaimer/:companyId",
      element: <DisclaimerPage />
    },
      {
          path: "invest/:companyId",
          element: <Investment />
      },
    {
      path: "faq/:companyId",
      element: <FAQPage />,
    },
    {
      path: "membership",
      element: <MembershipPage />,
    },
    {
      path: "membership/verify",
      element: <VerifyMembership  />
    },
    {
      path: "membership/thankyou",
      element: <MembershipThanksPage />
    },
    {
      path: "investment/verify/:orderId",
      element: <VerifyInvestment  />
    },
    {
      path: "investment/thankyou",
      element: <InvestmentThanksPage />
    }
  ]
);

export default router;