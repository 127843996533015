// src/App.js
import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import appStore, { AppContext } from './context/store';
import VerticalNavbar from './components/NavBar';
import { QueryClient, QueryClientProvider } from 'react-query';
import {Flip, ToastContainer} from "react-toastify";

function App() {
  const [appState, setAppState] = useState(appStore);
  const queryClient = new QueryClient();
  
  return (
      <div className="root-container">
          <AppContext.Provider value={{appState, setAppState}}>
              <QueryClientProvider client={queryClient}>
                  <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                      transition={Flip}
                  />
                  <RouterProvider router={router} />
              </QueryClientProvider>
          </AppContext.Provider>
      </div>
  );
}

export default App;
