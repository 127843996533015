import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactPlayer from "react-player/youtube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faVolumeMute,
  faPlay,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const VideoPlayer = ({
  url,
  title,
  actionUrl,
  description,
  onEnded,
  onReady,
  isVisible,
  videoIndex,
  muted,
  setMuted,
  hasUserInteracted,
}) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const isFirstRender = useRef(false);
  // Handle player ready state
  const handleReady = () => {
    setReady(true);
    onReady(true);
    if (isVisible) {
      setPlaying(true);
    }
  };

  // Update playing state when visibility changes
  useEffect(() => {
    if (isVisible && ready) {
      setPlaying(true);
      if (playerRef.current) {
        playerRef.current.getInternalPlayer()?.playVideo();
      }
    } else {
      setPlaying(false);
      if (playerRef.current) {
        playerRef.current.getInternalPlayer()?.pauseVideo();
      }
    }
  }, [isVisible, ready]);

  // Force pause when component unmounts or becomes invisible
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer()?.pauseVideo();
      }
    };
  }, []);

  const handleVideoClick = (e) => {
    e.stopPropagation();
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      if (playing) {
        player?.pauseVideo();
      } else {
        player?.playVideo();
      }
      setPlaying(!playing);
    }
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleToggleMute = useCallback(
    (e = null) => {
      if (e) {
        e.stopPropagation();
      }
      const player = playerRef.current?.getInternalPlayer();
      if (player) {
        if (muted) {
          player.unMute();
          setMuted(false);
        } else {
          player.mute();
          setMuted(true);
        }
      }
    },
    [muted, setMuted]
  );

  useEffect(() => {
    if (
      ready &&
      !isFirstRender.current &&
      hasUserInteracted &&
      isVisible &&
      playing &&
      !muted
    ) {
      const player = playerRef.current?.getInternalPlayer();
      player.unMute();
      isFirstRender.current = true;
    }
  }, [
    ready,
    handleToggleMute,
    videoIndex,
    isVisible,
    playing,
    muted,
    hasUserInteracted,
  ]);

  useEffect(() => {
    const hideYouTubeControls = () => {
      try {
        const iframe = playerRef.current?.getInternalPlayer();
        if (!iframe) return;

        // Try to access the iframe content safely
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow?.document;
        if (!iframeDoc) return;

        // Check if style element already exists
        const existingStyle = iframeDoc.getElementById("youtube-controls-hide");
        if (existingStyle) {
          existingStyle.remove();
        }

        const styles = `
                    .ytp-chrome-top,
                    .ytp-chrome-bottom,
                    .ytp-gradient-top,
                    .ytp-gradient-bottom,
                    .ytp-watermark,
                    .ytp-pause-overlay,
                    .ytp-related-videos-overlay,
                    .ytp-scroll-min,
                    .ytp-show-cards-title,
                    .ytp-pause-overlay-container,
                    .ytp-contextmenu,
                    .ytp-suggested-action,
                    .ytp-ce-element {
                        display: none !important;
                        opacity: 0 !important;
                        pointer-events: none !important;
                    }
                `;

        const styleElement = iframeDoc.createElement("style");
        styleElement.id = "youtube-controls-hide";
        styleElement.textContent = styles;
        iframeDoc.head.appendChild(styleElement);
      } catch (error) {
        console.log("Failed to hide YouTube controls:", error);
      }
    };

    if (ready) {
      const timer = setTimeout(hideYouTubeControls, 1000);
      return () => clearTimeout(timer);
    }
  }, [ready, playing]);

  return (
    <div className="relative h-screen w-full bg-black overflow-hidden">
      <div
        className="h-full w-full scale-125 cursor-pointer"
        onClick={handleVideoClick}
      >
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing && isVisible}
          controls={false}
          width="100%"
          height="100%"
          onEnded={onEnded}
          playsinline
          muted={muted}
          onReady={handleReady}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                showinfo: 0,
                rel: 0,
                controls: 0,
                autoplay: 0,
                playsinline: 1,
                enablejsapi: 1,
                mute: 1,
                origin: window.location.origin,
                iv_load_policy: 3,
                fs: 0,
                disablekb: 1,
                cc_load_policy: 0,
                annotations: 0,
                autohide: 0,
                branding: 0,
                widget_referrer: window.location.origin,
                hl: "en",
                wmode: "transparent",
                playlist: url?.split("v=")[1],
                loop: 1,
                endscreen: 0,
                related: 0,
                showRelatedVideos: false,
              },
              embedOptions: {
                autoplay: 0,
                showinfo: 0,
                modestbranding: 1,
                rel: 0,
                controls: 0,
                disablekb: 1,
                fs: 0,
                showRelatedVideos: false,
              },
            },
          }}
          style={{
            objectFit: "cover",
            pointerEvents: "none",
            opacity: 1,
            background: "black",
            marginTop: "-40px",
            height: "calc(100% + 40px)",
          }}
          onPlay={handlePlay}
          onPause={handlePause}
          className="youtube-player"
        />
      </div>

      {/* Play/Pause Overlay */}
      <div
        className="absolute inset-0 flex items-center justify-center cursor-pointer"
        onClick={handleVideoClick}
      >
        {!playing && (
          <div className="w-20 h-20 rounded-full bg-black/50 flex items-center justify-center">
            <FontAwesomeIcon
              icon={faPlay}
              className="w-8 h-8 text-white ml-1"
            />
          </div>
        )}
      </div>

      {/* Updated Floating Info Box */}
      <div
        className="absolute bottom-0 left-0 w-full px-2 pb-safe z-50 bg-black/50 backdrop-blur-md"
        style={{
          marginBottom: "8rem",
          background: "transparent",
          minHeight: "100px",
          display: "flex",
          alignItems: "flex-end",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <div
          className="p-4 w-full rounded-lg"
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <div className="flex w-full items-center justify-between mb-2">
            <div className="flex-col">
              <h3
                className="text-white font-bold text-md truncate mr-4 w-[calc(100%-4rem)]"
                style={{
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
              >
                {title.length > 20
                  ? `${title.substring(0, 30)}...`
                  : title || "Bloomsight Ventures"}
              </h3>
              <p className="text-white text-sm line-clamp-1 px-2 py-4">
                PYQ | FinTech
              </p>
            </div>
            <button
              onClick={handleToggleMute}
              className={`relative bg-black/50 text-white p-3 w-10 h-10 border border-solid border-orange-500 rounded-full
                z-50 shadow-lg flex items-center justify-center shrink-0 ${
                  muted ? "animate-pulse" : ""
                }`}
            >
              <FontAwesomeIcon
                icon={muted ? faVolumeMute : faVolumeUp}
                className="w-6 h-6 mute-button"
                style={{ color: muted ? "white" : "white" }}
              />
            </button>
          </div>
          <p
            className="text-white text-sm line-clamp-1 mb-4"
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              WebkitLineClamp: 3,
            }}
          >
            {description || "We connect visionary startups with eager angel investors, streamlining the funding process and ensuring rapid, reliable funding."}
          </p>
          
          {/* New Details Button */}
          <div className="flex w-full mt-2">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = actionUrl;
              }}
              className="w-full bg-orange-500 text-white px-6 py-2 rounded-full font-medium 
                         hover:bg-orange-600 transition-colors duration-200 
                         flex items-center justify-center gap-2 shadow-lg
                         border border-orange-400"
            >
              Open Details
              <svg 
                className="w-4 h-4" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
